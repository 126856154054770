import "./Header.css";
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const Header = ({monto, OpenModalCarrito}) => {

    // const [suc, setSuc] = useState('Escobar');
    // const [suc, setSuc] = useState('Garin');
    const [suc, setSuc] = useState('');

    const actualUrl = window.location.hostname.split(".").length == 4 ? window.location.hostname.split(".")[1] : window.location.hostname.split(".")[0]  ;

    useEffect(()=>{
      if(actualUrl === 'sushisakegarin'){
        setSuc('Garin')
      } else if(actualUrl === 'sushisakesanisidro'){
        setSuc('San Isidro')
      }else if(actualUrl === 'sushisakeescobar'){
        setSuc('Escobar')
      }else {
        setSuc('Test Local')
      }
      //alert(actualUrl)
    },[actualUrl])


    return (
        <>
        <Helmet>
            <title> Sushi Sake | {suc}</title>
        </Helmet>
        <header className="header">
            <div className="col-2">
                {/* <a  onClick={OpenModalCarrito}> */}
                    <img src="./img/LogoSushi.png" alt="Logo Sushi" width="70" height="70" />
                    {/* <img src="/img/LogoSushi.png" width="90" height="90" alt="Logo Sushi"/> */}
                {/* </a> */}
            </div>
            <div className="col-8">
                <p className="pheader" Style="padding-left:15px; font-family: Nunito,serif; font-size: 1.4em; color: #101010; text-align: center; text-wrap: nowrap;">
                    {suc}
                    </p>
                {/* <p className="pheader">011-1111-4455</p> */}
            </div>
            <div className="col-2" >
                <div Style={"cursor: pointer;"} onClick={OpenModalCarrito}>
                    {monto > 0 &&
                        <img src="./img/icon_cart_full.svg" alt="Acceso a Ver y Finalizar tu compra"/>
                    }
                    {monto == 0 &&
                        <img src="./img/icon_cart_empty.svg" alt="Acceso a Ver y Finalizar tu compra"/>
                    }
                </div>
            </div>
        </header>
        </>
    );
}

export default Header;