import { useState } from "react";

export const useModal = (initialValue = false) => {
    const [isOpen, setIsOpen] = useState(initialValue);

    const openModal  =()=> {
        setIsOpen(true);
        //document.getElementById('body').className = "sinScroll";
    }
    const closeModal  =()=> {
        setIsOpen(false);
        //document.getElementById('body').className = "";
    }

    return [isOpen, openModal, closeModal];
};