import { useState } from "react";

export const useForm = (initialForm, validateForm) => {
    const [form, setForm] = useState(initialForm);

    const handleChange =(e) =>{
        const {name, value} = e.target;
        setForm({
            ...form,
            [name]: value,
        })
    };
    // cuand pierda el enfoque de la pagina
    const handleBlur =(e) =>{
        handleChange(e)
        console.log('handleBlur')
        console.log(form)
    };
    const handleSubmit =(e) =>{
        handleChange(e)
        console.log('handleSubmit')
        console.log(form)
    };

    return {form, handleChange, handleBlur, handleSubmit, setForm}
};