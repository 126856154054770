import '../App.css';
const Producto = ({data, openModal}) => {

  const formato = new Intl.NumberFormat('de-DE');

  function Accion() {
        openModal(data.id);
      }

      return(
          <>
            <div key ={data.id} className="col-xxs-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 caja-producto-contenedor" Style={"display: flex;padding-bottom: 5px; border-bottom: 1px solid #999; padding-right: 5px;    padding-left: 5px; padding-top: 5px;"}>
              <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3" Style={"display: flex;flex-direction: column;"}> 
                <img src={data.src} alt={data.nombre}  Style={"max-width: 100%;height: auto;border-radius: 7px; cursor: pointer;"}/>
              </div>
              <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9" Style={"display: flex;  flex-direction: column;"}>
                <div Style={"display: flex;    padding-top: 0.5em;    flex-direction: row;    flex-wrap: wrap;"}>
                  <h3 className="col-xs-6 col-sm-6 col-md-6 col-lg-6 desliz" Style={"font-size: .9em; color: #101010; margin-top: 0; margin-bottom: 0.5em; overflow-x: hidden; overflow-y: hidden; white-space: nowrap; text-align: start; padding-left: 15px; cursor: pointer;"}>
                      <div>{data.nombre}</div>
                  </h3>
                  <div className="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                    <button onClick={Accion} type="button" className="btn btn-success"  Style={"border-radius: 6.4px;    border: transparent;    font-size: .87em; float: right;"}>
                      <span Style={"font-family: Nunito;  font-size: .9em;   color: #fff;"}>
                      ¡AÑADIR!
                      </span>
                    </button>
                  </div>
                <h3 className="col-xs-6 col-sm-6 col-md-6 col-lg-6" Style={"font-size: .9em; color: #101010; margin-top: 0; margin-bottom: 0.5em; overflow-x: hidden; overflow-y: hidden; white-space: nowrap; text-align: start; padding-left: 20px; margin-top: -0.5em;"}>
                {data.cantidad}</h3>
                </div>
              <div Style={"display: inline-flex;"}>
                <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9" >
                  <p Style={"font-size: 1.2em; color: #101010; margin-top: 0; margin-bottom: 0.5em; overflow-x: hidden; overflow-y: hidden; white-space: nowrap; text-align: start; padding-left: 20px; margin-top: 15px;"} >
                     
                    {data.piezas && <>
                      $ {formato.format(data.piezas[0].precio)} - $ {formato.format(data.piezas[data.piezas.length-1].precio)}  
                     </>
                    }
                    {!data.piezas && data.promo &&<>
                      $ {formato.format(data.precioPromo)} &nbsp;
                      <strike>
                      $ {formato.format(data.precio)}
                      </strike>

                      {/* &nbsp;&nbsp;&nbsp;
                      
                      {formato.format(Math.round((1-(data.precioPromo/data.precio))*100))} % OFF */}

                     </>
                    }
                    {!data.piezas && data.promo !== true &&<>
                      $ {formato.format(data.precio)}  
                     </>
                    }
                  </p>
                </div>
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3" >
                  <p Style={"font-size: 1.2em; color: red; overflow-x: hidden; overflow-y: hidden;text-align: start;padding-left: 20px;"} >
                    {!data.piezas && data.promo &&<>
                      {formato.format(Math.round((1-(data.precioPromo/data.precio))*100))}% OFF
                     </>
                    }
                  </p>
                </div>

              </div>
              </div>
            </div>
          </>
      );
  };
  
  export default Producto;