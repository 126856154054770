import { useState } from "react";

export const useCantidad = (initialValue = 1) => {
    const [cantidad, setCantidad] = useState(initialValue);

    const restarCantidad  =()=> {
        return cantidad-1 === 0 ? setCantidad(cantidad):setCantidad(cantidad-1);
    };
    const sumarCantidad   =()=> {
        return cantidad+1 === 11 ? setCantidad(cantidad):setCantidad(cantidad+1);
    };
    const resetCantidad   =()=> setCantidad(1);

    return [cantidad, restarCantidad, sumarCantidad, resetCantidad];
};