
const Footer = ({monto, OpenModalCarrito}) => {

    const formato = new Intl.NumberFormat('de-DE');

    return <>
        <div Style="position: fixed;    width: 100%;    bottom: 0;    background: white;     padding-bottom: 0.75rem; border-top: dashed;   padding-top: 0.75rem;">
            <div Style="margin-left: 0.75rem; margin-right: 0.75rem;">
                <button onClick={OpenModalCarrito} Style="color: white; width: 100%; font-size: 1rem; font-weight: 700; display: flex; justify-content: space-around; background: #198754; border-color: transparent; border-radius: 6px;">
                    <div>Ver Pedido</div>
                    <div>${formato.format(monto)}</div>
                </button>
            </div>
        </div>
    </>
};

export default Footer;