import { useState } from "react";

export const useCarrito = (initialValue) => {
    const [carrito, setCarrito] = useState(initialValue);

    const addProduct = (prod) => {
        setCarrito([...carrito, prod])
    };

    const deleteProduct = (id) => {
        const newCarrito = carrito.filter((prod, index) => index !== id);
        setCarrito(newCarrito);
    };

    const emptyCarrito = () => setCarrito([])
 
    return [carrito, addProduct, deleteProduct, emptyCarrito];
};