import "./Modal.css";
import React, { useState, useEffect } from 'react';
import HeaderModal from './HeaderModal';
import { useCantidad } from '../hooks/useCantidad';

const ModalProductos = ({isOpen, closeModal, data, addProduct, openModalFinalizar}) => {
    
const handleModalContainerClick = e => e.stopPropagation();

const [cantidad, restarCant, sumarCant, resetCant] = useCantidad(1); // Modal Productos

const [selectedPiezas, setSelectedPiezas] = useState('');
const [precio, setPrecio] = useState('');
const [promo, setPromo] = useState('');
const [precioOriginal, setPrecioOriginal] = useState('');
const [subtotal, setSubtotal] = useState('');

const formato = new Intl.NumberFormat('de-DE');

useEffect(() => {

    if (data?.piezas){
        setSelectedPiezas(data?.piezas[0].x)
        // console.log(selectedPiezas)
        setPrecio(data?.piezas[0].precio)
        setPromo(data?.piezas[0].promo)
        setPrecioOriginal("")
    }else{
        setSelectedPiezas('')
        // console.log(selectedPiezas)
        setPrecio(data?.promo ? data?.precioPromo : data?.precio)
        setPromo(data?.promo)
        setPrecioOriginal(data?.promo ? data?.precio : "")
    }

    // let subtotal =0;
    // carrito.forEach( (prod) => {
    //   subtotal =subtotal+parseInt(prod.precioS);
    // });

  },[data]);

  function close() {
    setSelectedPiezas('')
    resetCant();
    closeModal();    
  }

  function addSeguir() {
    addProduct({uid:data?.uid, nombre:data?.nombre, piezas:selectedPiezas, cantidad: cantidad, precio:precio, promo:promo})
    close();
    
  }

  function addFinalizar() {
    addProduct({uid:data?.uid, nombre:data?.nombre, piezas:selectedPiezas, cantidad: cantidad, precio:precio, promo:promo})
    openModalFinalizar();
    close();
  }



  useEffect(()=>{
    if(selectedPiezas !== ''){
        if(data?.piezas){
            let p = data?.piezas?.filter((p) => p.x === selectedPiezas)[0]
            setPrecio(p.promo? p.precioPromo:p.precio)
            setPromo(p.promo)
            setPrecioOriginal(p.promo? p.precio:'')
        }
    }
  },[selectedPiezas])

  useEffect(()=>{
    setSubtotal(cantidad * precio);
  },[precio, cantidad, selectedPiezas])



    return(
        <div className={isOpen === true ? "modalInfo is-open" : "modalInfo"} onClick={close} >
            <div className="modalInfo-container" onClick={handleModalContainerClick}>
                <button className="modalInfo-close" onClick={close}><img alt="Salir" src="./img/icon_salir.svg" width="40" height="40" className="d-inline-block align-top" /></button>
                <HeaderModal Style={"background=white"}/>
                <div Style={"    padding: 15px;    border-bottom: 1px solid #e5e5e5; display: flex; flex-wrap: wrap; background-color: darkseagreen;"}>
                    <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3" Style={"display: flex;  justify-content: center;margin-bottom: 10px;"}>
                        <img Style={"display: block; max-height: 150px; max-width: 150px; border-radius: 15px;"} alt={data?.nombre} src={data?.src}/>
                    </div>
                    <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9" Style={"display: flex;    flex-wrap: wrap;    padding-left: 20px; justify-content: center; margin-bottom: 10px;"} >
                        <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                            <h3 Style={"font-family: Nunito;    font-size: 1.4em;    color: black;    white-space: nowrap;    margin-top: 0;"}>
                                {data?.nombre}
                            </h3>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                            <p Style={"font-size: 1.2em;    color: #000;    margin-top: 0;    margin-bottom: 0;    white-space: nowrap;"}>
                            {data?.piezas && <>
                            $ {formato.format(data?.piezas[0].precio)} - $ {formato.format(data?.piezas[data?.piezas.length-1].precio)}  
                            </>
                            }
                            {!data?.piezas && data?.promo &&<>
                            $ {formato.format(data?.precioPromo)} &nbsp;
                            <strike>
                            $ {formato.format(data?.precio)}
                            </strike>
                            </>
                            }
                            {!data?.piezas && data?.promo !== true &&<>
                            $ {formato.format(data?.precio)}  
                            </>
                            }
                            </p>
                            <p Style={"font-size: 1.2em; color: red;"} >
                                {!data?.piezas && data?.promo && <>
                                    {formato.format(Math.round((1-(data?.precioPromo/data?.precio))*100))}% OFF
                                    </>
                                }
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <h4 Style={"margin-top: 0;    margin-bottom: 0;    font-size: 1em;    line-height: 1.6;    color: black;"}>
                                {
                                    data?.detalle.split('//').map((det) => {
                                        return  <>
                                            {det} <br/>
                                        </>
                                    })
                                }
                            </h4>
                        </div>
                    </div>
                </div>
                <div Style={"padding: 7px;"}>
                    <div Style={"display: flex;    justify-content: space-around;"}>
                        <div Style={"display: flex;   align-items: center;"}>
                            <button type="button" className="boton" onClick={restarCant}>-</button>
                            <input type="text" className="cantidad" name="cantidad" value={cantidad} disabled="disabled"/>
                            <button type="button" className="boton" onClick={sumarCant}>+</button>
                        </div>
                        {
                            selectedPiezas !== '' && <>
                            <div>
                                <div>
                                        {/* <select v-model="selected"> */}
                                        <select value={selectedPiezas} onChange={e => setSelectedPiezas(e.target.value)}>
                                            {/* <option disabled value=""></option> */}
                                            {
                                                data?.piezas?.map((p) => {
                                                    return  <>
                                                        <option value={p.x}>{p.x}</option>
                                                    </>
                                                })
                                            }
                                            </select>
                                    
                                   
                                </div>
                                <div>
                                    $ {precio} 
                                    {precioOriginal !="" && <>
                                            &nbsp;
                                            &nbsp;
                                        <strike>${precioOriginal}</strike>
                                    </>
                                    }
                                    {/* {
                                        selectedPiezas !== '' && <>
                                         {data?.piezas?.filter((p) => p.x == selectedPiezas)[0].precio}
                                        </>
                                    } */}
                                </div>
                            </div>
                            </>
                        }
                        <div>
                            <div>Subtotal: </div>
                            <div>$ {subtotal} </div>
                        </div>
                    </div>
                    <div>
                        <button type="button" className="botonVerde" data="seguir" onClick={ addSeguir }>Añadir y seguir</button>
                    </div>
                    <div>
                        <button type="button" className="botonCeleste" data="salir" onClick={ addFinalizar }>Añadir e ir a pagar</button>   
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalProductos;