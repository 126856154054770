import "./Modal.css";

const ModalCarrito = ({carrito, deleteProduct, monto, openModalFinalizar, closeModalCarrito}) => {

    const formato = new Intl.NumberFormat('de-DE');

    const handleModalContainerClick = e => e.stopPropagation();

    return(
        <div className="modalCarrito" onClick={closeModalCarrito} >
            <div className="modalCarrito-container" onClick={handleModalContainerClick}>
                <button className="modalCarrito-close" onClick={closeModalCarrito}><img alt="Cerrar Carrito" src="./img/icon_salir_blanco.svg" width="40" height="40" className="d-inline-block align-top" /></button> 
                <div className='p-2' Style="background: black;">
                    <div Style="background-color: black; padding-bottom: 8px; padding-top: 14px; color: white;">
                        <h3> Carrito </h3>
                    </div>
                </div>
                { monto > 0 &&
                    <div>
                        <div Style='font-size: small;'>
                            <div Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px; background-color: darkgray; width: inherit;--bs-gutter-x: 0;">
                                    <div Style={"flex: 1 0 0%;"}>Cantidad</div> 
                                    <div Style={"flex: 1 0 0%;"}>Producto</div> 
                                    <div Style={"flex: 1 0 0%;"}>Precio</div>
                                    <div Style={"flex: 1 0 0%;"}>Subtotal</div> 
                                    <div Style={"flex: 1 0 0%;"}>Eliminar</div>
                            </div>
                            {
                                carrito.map( (prod, index) => {
                                    return  <>
                                    <div key={prod.id} Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                        <div Style={"flex: 1 0 0%;"}>{prod.cantidad}</div>
                                        <div Style={"flex: 1 0 0%;"}>{prod.nombre} {prod.piezas? prod.piezas:''}</div>
                                        <div Style={"flex: 1 0 0%;"}>${formato.format(prod.precio)}</div>
                                        <div Style={"flex: 1 0 0%;"}>${formato.format(prod.precio * prod.cantidad)}</div>
                                        <div Style={"flex: 1 0 0%;"} onClick={() => {deleteProduct(parseInt(index))}} > <img alt="Eliminar" src="./img/icon_eliminar.svg" width="30" height="30" className="d-inline-block align-top" /></div>
                                    </div>
                                </>;
                                })
                            }
                        </div>
                        <hr />
                        <div> SubTotal: ${formato.format(monto)}</div>
                        <div>
                            <div Style="margin-left: 0.75rem; margin-right: 0.75rem; margin-top: 0.75rem;">
                                <button onClick={closeModalCarrito} className="botonVerde">
                                    <div>Continuar Comprando</div>
                                </button>
                            </div>
                            <div Style="margin-left: 0.75rem; margin-right: 0.75rem; margin-top: 0.15rem;">
                                <button onClick={()=>{closeModalCarrito(); openModalFinalizar()}} className="botonCeleste">
                                    <div>Finalizar Pedido</div>
                                </button>
                            </div>
                        </div>
                    </div>
                }
                { monto == 0 &&
                <div Style="margin-top: 2rem;">
                    El carrito esta vacio <br></br>
                    <img alt="" src="./img/icon_cart_empty.svg" width="90" height="90"/>

                    <div Style="margin-left: 0.75rem; margin-right: 0.75rem; margin-top: 0.75rem;">
                        <button onClick={closeModalCarrito} className="botonVerde">
                            <div>Continuar Comprando</div>
                        </button>
                    </div>
            
                </div>

                }
            </div>
        </div>
    );
};

export default ModalCarrito;