import "./Header.css";

const HeaderModal = () => {

    return (
        <>
        <header className="header">
            <div className="col-12">
                <a href="/">
                    <img src="./img/Logo-Sushi-Sake.jpg" alt="Logo Sushi"/>
                </a>
            </div>
        </header>
        </>
    );
}

export default HeaderModal;